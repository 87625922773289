import {PaymentSerializer, RemitSerializer, RemitSimpleSerializer} from '../@core/api.service';
import {ModelBase} from './model-base';

export class Remittance extends ModelBase implements RemitSimpleSerializer {
    id?: number;
    payment?: PaymentSerializer;
    claim?: number;
    first_name?: string;
    last_name?: string;
    origin_claim_id?: string;
    paid?: number;
    pos?: string;
    displayPaid: string;

    static getIdentifier(x) {
        return `BST${x.id}`;
    }

    assign(remit: RemitSerializer) {
        super.assign(remit);
        //TODO: Implement currency pipe
        this.displayPaid = `$ ${this.paid}`;
    }
}
