export const SERVICE_LINE_STATUSES = {
    PAID: 'Paid',
    PAID_BY_PRIMARY: 'Paid by Primary',
    PARTIALLY_PAID: 'Partially Paid',
    OVERPAID: 'Overpaid',
    ALLOWED: 'Allowed',
    DENIED_MISSING_INFO: 'Denied - Missing info',
    DENIED: 'Denied',
    ERROR: 'Error',
};
