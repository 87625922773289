export const CLAIM_STATUSES = {
    DRAFT: 'Draft',
    HOLD_FOR_FUTURE: 'Hold for future submission',
    READY_TO_BILL: 'Ready to bill',
    QUEUED_FOR_SUBMISSION: 'Queued for submission',
    UPLOADED: 'Uploaded to CH',
    ERROR: 'Error',
    ACCEPTED: 'Accepted by CH',
    PENDING: 'Pending',
    REJECTED: 'Rejected',
    PAID: 'Paid',
    PAID_BY_PRIMARY: 'Paid by Primary',
    PARTIALLY_PAID: 'Partially Paid',
    OVERPAID: 'Overpaid',
    ALLOWED: 'Allowed',
    DENIED_MISSING_INFO: 'Denied - Missing info',
    DENIED: 'Denied',
    UNBILLABLE: 'Unbillable',
    COMPLETED: 'Completed',
};
