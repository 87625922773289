interface AddressDataBase {
    city: string;
    state: string;
    zip: string;
}

interface AddressDataWithSingleLine extends AddressDataBase {
    address: string;
}

interface AddressDataWithMultiLines extends AddressDataBase {
    addressLines: string[];
}

export type AddressData = AddressDataWithSingleLine | AddressDataWithMultiLines;

export const getFullAddressLines = (data: AddressData) => [
    ...((data as AddressDataWithMultiLines).addressLines || [(data as AddressDataWithSingleLine).address]),
    [data.city, [data.state, data.zip].filter(x => x).join(' ')].filter(x => x).join(', '),
].filter(x => x);

export const getMultilineFullAddress = (data: AddressData) => getFullAddressLines(data).join('\n');
